.spotlight-container {
    position: relative;
}
.spotlight {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 9999; /* Ensure the spotlight is above other content */
} 

/* .spotlight {
    pointer-events: none;
    position: absolute;
    inset: -1px;
    opacity: 0;
    transition: opacity 300ms;
    z-index: 9999;
} */